<script>
import { FormWizard, TabContent } from "@anivive/vue3-form-wizard";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import CKEditor from "@ckeditor/ckeditor5-vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from '@/components/widgets/loader';
import Swal from 'sweetalert2';
import router from '@/router';

import brandGetall from '../../../state/api/brandGetAll';
import getFindByBrandId from '../../../state/api/getFindByBrandId';
import categoryGetFindById from "../../../state/api/categoryGetFindById";
//import getFindByCategoryIdList from '../../../state/api/getFindByCategoryIdList';
import LanguageGetAll from "../../../state/api/LanguageGetAll";
import UserTypeGetAll from "../../../state/api/UserTypeGetAll";
import categorySaveOrUpdate from "../../../state/api/categorySaveOrUpdate";

/**
 * Form wizard component
 */
export default {
  page: {
    title: "Form Wizard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  //components: { Layout, PageHeader, FormWizard, TabContent,ckeditor: CKEditor.component,Loader },
  components: { Layout, PageHeader, FormWizard, TabContent,Loader },
  
  data() {
    return {
      title: "Edit Category",
      items: [
        {
          text: "Categories",
          href: "/category/category-list",
        },
        {
          text: "Edit Category",
          active: true,
        },
      ],
      id:this.$route.params.id,
      catagoryList : [],
      languageList : [],
      UserTypeList : [],
      brandList : [],
      brandParentId:null,
      categoryDetail:{
        languageId:"",
        userTypeId:"",
        parentId:"",
        brandId:"",
        name:"",
        shortDescription:"",
        longDescription:"",
        isHtml:false,
        isHome:false,
        isFavoriteFlag:false,
        isNewFlag:false,
        homePageOrderBy:"",
        orderBy:"",
        isActive:true
      }
    };
  },
  methods:{
    async brandGetall(){
        const {getBrandGetAll} = brandGetall()
        const response = await getBrandGetAll();
        this.brandList = response.data;             
    },
    async getCatagoryDetail(){
        const {getFindById} = categoryGetFindById()
        const response = await getFindById(this.id);
        this.categoryDetail = response.data;

        const {catFindByBrandId} = getFindByBrandId()
        const response2 = await catFindByBrandId(this.categoryDetail.brandId,this.brandParentId);
        this.catagoryList = response2.data.categoryList; 
    },
    // async getCatagoryList(){
    //     const {CategoryIdList} = getFindByCategoryIdList()
    //     const response = await CategoryIdList();
    //     this.catagoryList = response.data;           
    // },
    async LanguageGetAll(){
        const {LanguageGet} = LanguageGetAll()
        const response = await LanguageGet();
        this.languageList = response.data;           
    },
    async UserTypeGetAll(){
        const {userTypeGet} = UserTypeGetAll()
        const response = await userTypeGet();
        this.UserTypeList = response.data;           
    },
    async catFilter(){
        this.catagoryList =[];
        const {catFindByBrandId} = getFindByBrandId()
        const response = await catFindByBrandId(this.categoryDetail.brandId,this.brandParentId);
        this.catagoryList = response.data.categoryList; 
    },
    getAddCategory(){
        let update = {
            "Id":this.id,
            "Name":this.categoryDetail.name,
            "ParentId":this.categoryDetail.parentId,
            "BrandId":this.categoryDetail.brandId,
            "UserTypeId":this.categoryDetail.userTypeId,
            "ShortDescription":this.categoryDetail.shortDescription,
            "LongDescription":this.categoryDetail.longDescription,
            "IsHtml":this.categoryDetail.isHtml,
            "IsHome":this.categoryDetail.isHome,
            "IsFavoriteFlag":this.categoryDetail.isFavoriteFlag,
            "IsNewFlag":this.categoryDetail.isNewFlag,
            "OrderBy":this.categoryDetail.orderBy,
            "HomePageOrderBy":this.categoryDetail.homePageOrderBy,
            "LanguageId":this.categoryDetail.languageId,
            "isActive":this.categoryDetail.isActive
        }
        async function updateCategory() {
            const {saveOrUpdate} = categorySaveOrUpdate()
            await saveOrUpdate(update).
            then(res=>{
                if(res.isSuccess){
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    router.push({name:'category-list'})
                }
            })
        }
        updateCategory();
    },
  },
  mounted(){
    this.getCatagoryDetail();
    //this.getCatagoryList();
    this.LanguageGetAll();
    this.UserTypeGetAll();
    this.brandGetall();
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
        <Loader :loading="categoryDetail">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="row">
                            <div class="col-lg-10">
                                <div class="card-body">
                                    <form-wizard color="#556ee6">
                                        <tab-content icon="mdi mdi-account-circle">
                                            <div class="row">
                                                <div class="col-12">                                                    
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="language"
                                                            >{{$t('user.languageSelect')}} <span class="text-danger">*</span></label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="categoryDetail.languageId" required>
                                                                <option v-for="language in languageList" :key="language.id" :value="language.id">{{language.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="userTypeId"
                                                            >{{$t('user.UserSelect')}} <span class="text-danger">*</span></label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="categoryDetail.userTypeId" required>
                                                                <option v-for="user in UserTypeList" :key="user.id" :value="user.id">{{user.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="selectedBrand"
                                                            >{{$t('user.BrandSelect')}} <span class="text-danger">*</span></label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="categoryDetail.brandId" @change="catFilter">
                                                                <option v-for="brand in brandList" :key="brand.id" :value="brand.id">{{brand.title}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="parentId"
                                                            >{{$t('user.MainCategory')}} <span class="text-danger">*</span></label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="categoryDetail.parentId" required>
                                                                <option v-for="cat in catagoryList" :key="cat.id" :value="cat.id">{{cat.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="name"
                                                            >{{$t('user.CategoryTitle')}} <span class="text-danger">*</span></label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="text"
                                                            name="CategoryTitle"
                                                            class="form-control"
                                                            v-model="categoryDetail.name"
                                                            required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="shortDescription"
                                                            >{{$t('user.shortDescription')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="text"
                                                            name="shortDescription"
                                                            class="form-control"
                                                            v-model="categoryDetail.shortDescription"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isHtml"
                                                            >{{$t('user.isHtml')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="categoryDetail.isHtml" name="isHtml" switch >
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="longDescription"
                                                            >{{$t('user.longDescription')}}</label
                                                        >
                                                        <div class="col-md-9">                                                
                                                            <!-- Editor -->
                                                            <textarea v-model="categoryDetail.longDescription" rows="10" class="w-100"></textarea>
                                                            <!-- <ckeditor v-model="categoryDetail.longDescription" :editor="editor">{{ categoryDetail.longDescription }}</ckeditor> -->
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isHome"
                                                            >{{$t('user.HomePageIsTrue')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="categoryDetail.isHome" name="check-button" switch>
                                                        </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="orderBy"
                                                            >{{$t('user.OrderNo')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="number"
                                                            name="OrderNo"
                                                            class="form-control"
                                                            v-model="categoryDetail.orderBy"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="homePageOrderBy"
                                                            >{{$t('user.homePageOrderBy')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="number"
                                                            name="homePageOrderBy"
                                                            class="form-control"
                                                            v-model="categoryDetail.homePageOrderBy"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isHtml"
                                                            >{{$t('user.Active')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="categoryDetail.isActive" name="isActive" switch >
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="IsFavoriteFlag"
                                                            >{{$t('user.IsFavoriteFlag')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="categoryDetail.isFavoriteFlag" name="check-button" switch>
                                                        </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isNewFlag"
                                                            >{{$t('user.isNewFlag')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="categoryDetail.isNewFlag" name="check-button" switch>
                                                        </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="mt-2">
                                                        <button type="submit" class="btn btn-primary me-1"  @click="getAddCategory">
                                                        {{$t('user.SaveChanges')}}
                                                        </button>
                                                    </div>
                                                </div>                                    
                                                <!-- end col -->
                                            </div>
                                            <!-- end row -->
                                        </tab-content>
                                    </form-wizard>
                                </div>
                                <!-- end card-body -->
                            </div>
                        </div>            
                    </div>
                    <!-- end card -->
                </div>
                </div>
                <!-- end row -->
        </Loader>    
  </Layout>
</template>



