import apiPath from './apiPath'
import {ref} from 'vue'
import { useRouter } from 'vue-router';

const categorySaveOrUpdate =()=>{
    const hata = ref(null);
    const router = useRouter();
    const path = apiPath;
    let users = JSON.parse(localStorage.getItem('user'));
    const saveOrUpdate = async(update)=>{
        hata.value = null;
        try {
            const res = await fetch(path + "category/saveOrUpdate", {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    "Id": update.Id,
                    "Name": update.Name,
                    "ParentId": update.ParentId,
                    "BrandId": update.BrandId,
                    "UserTypeId": update.UserTypeId,
                    "ShortDescription": update.ShortDescription,
                    "LongDescription": update.LongDescription,
                    "IsActive":update.IsActive,
                    "IsHtml":update.IsHtml,
                    "IsHome":update.IsHome,
                    "isFavoriteFlag":update.IsFavoriteFlag,
                    "isNewFlag":update.IsNewFlag,
                    "orderBy":update.OrderBy,
                    "homePageOrderBy":update.HomePageOrderBy,
                    "LanguageId":update.LanguageId,
                    "TokenKey": localStorage.getItem('token'),                    
                    "UserId":users.id,
                    "RequestMethodName" :"/api/category/saveOrUpdate",
                    "RequestMethodType" : "POST"
                }), 
            })
            const data = await res.json();
            if(data.errorMessage == "Error, token expired."){
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                router.push({name:'login'})              
            }
            else{
                if(data.isSuccess){
                    return data
                }
            }
        } catch (error) {
            hata.value = error.message
        }
    }
    
    return {saveOrUpdate}
}

export default categorySaveOrUpdate